import axios from 'axios';

const url_prefix = window.location.protocol + '//' + window.location.hostname;
export const AI_API_ROOT = url_prefix + process.env.VUE_APP_AI_API_SUFFIX;
export const API_ROOT = url_prefix + process.env.VUE_APP_API_SUFFIX;
export const GRAT_V2_API_ROOT = url_prefix + process.env.VUE_APP_GRAT_V2_API_SUFFIX;
export const CRUT_V1_API_ROOT = url_prefix + process.env.VUE_APP_CRUT_V1_API_SUFFIX;
export const CRUT_V2_API_ROOT = url_prefix + process.env.VUE_APP_CRUT_V2_API_SUFFIX;
export const CAPITAL_GAINS_COMPARISON_API_ROOT =
    url_prefix + process.env.VUE_APP_CAPITAL_GAINS_COMPARISON_API_SUFFIX;
export const ESTATE_COMPARISON_API_ROOT = url_prefix + process.env.VUE_APP_ESTATE_COMPARISON_API_SUFFIX;
export const ORDINARY_INCOME_COMPARISON_API_ROOT = url_prefix +
    process.env.VUE_APP_ORDINARY_INCOME_COMPARISON_API_SUFFIX;
export const IRA_API_ROOT = url_prefix + process.env.VUE_APP_IRA_API_SUFFIX;
export const OIL_GAS_API_ROOT = url_prefix + process.env.VUE_APP_OIL_GAS_API_SUFFIX;
export const PPLI_API_ROOT = url_prefix + process.env.VUE_APP_PPLI_API_SUFFIX;
export const QSBS_API_ROOT = url_prefix + process.env.VUE_APP_QSBS_API_SUFFIX;
export const SOLAR_API_ROOT = url_prefix + process.env.VUE_APP_SOLAR_API_SUFFIX;
export const ASGARD_API_ROOT = process.env.VUE_APP_ASGARD_URL;

export const addOrEditItem = (path, root_path = '') => {
    return async ({ dispatch, rootGetters }, payload = {}) => {
        let method = 'post';
        let userId = rootGetters.user.id;
        if (payload.user_id) {
            userId = payload.user_id;
            delete payload.user_id;
        }
        let additional_path = '';
        if (root_path !== '') {
            additional_path = `${root_path}${payload.additional_id}/`;
            delete payload.additional_id;
        }
        let url = `${API_ROOT}/${userId}/${additional_path}${path}`;
        if (payload.id) {
            method = 'patch';
            url = `${url}${payload.id}/`;
            delete payload.id;
        }
        if (payload.action) {
            url = `${url}${payload.action}/`;
            delete payload.action;
        }
        try {
            const { data } = await axios[method](
                url,
                payload,
                rootGetters.axiosConfig,
            );
            return data;
        } catch (e) {
            logApiError(e, dispatch);
            throw e;
        }
    };
};

export const deleteItem = (path, root_path = '', safe = false) => {
    return async ({ dispatch, rootGetters }, payload = {}) => {
        const id = payload.id;
        let userId = rootGetters.user.id;
        if (payload.user_id) {
            userId = payload.user_id;
            delete payload.user_id;
        }
        let additional_path = '';
        if (root_path !== '') {
            additional_path = `${root_path}${payload.additional_id}/`;
            delete payload.additional_id;
        }
        try {
            const url = `${API_ROOT}/${userId}/${additional_path}${path}${id}/`;
            if (safe) {
                await axios.patch(url, { is_deleted: true }, rootGetters.axiosConfig);
            } else {
                await axios.delete(url, rootGetters.axiosConfig);
            }

            return true;
        } catch (e) {
            logApiError(e, dispatch);
            return false;
        }
    };
};

export const getItems = (path, root_path = '') => {
    return async ({ dispatch, rootGetters }, payload = { context: {}, id: null }) => {
        let userId = rootGetters.user.id;
        if (payload.user_id) {
            userId = payload.user_id;
            delete payload.user_id;
        }
        const module = `${userId}`;
        const id = payload.id;
        let p = path;
        let additional_path = '';
        if (root_path !== '') {
            additional_path = `${root_path}${payload.additional_id}/`;
            delete payload.additional_id;
        }
        if (id) {
            p = `${path}${id}/`;
        }
        const url = `${API_ROOT}/${module}/${additional_path}${p}`;
        try {
            const { data } = await axios.get(
                url,
                rootGetters.axiosConfig,
            );
            return data;
        } catch (e) {
            logApiError(e, dispatch);
            return false;
        }
    };
};

export const logApiError = (error, dispatch) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 401 || error.response.status === 403) {
            dispatch('logout', null, { root: true });
        } else if (error.response.status !== 409) {
            // Conflicts are most likely to occur in response to a PUT request.
            // For example, you may get a 409 response when uploading a file which
            // is older than the one already on the server resulting in a version control conflict.
            // We dont want the user to see this.
            console.debug(error, error.response);
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error, error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Internal Error', error, error.message);
    }
};