import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';


export default {
    namespaced: true,
    state: {
        availableProducts: [],
    },
    mutations: {
        updateAvailableProducts(state, availableProducts) {
            state.availableProducts = availableProducts;
        },
    },
    getters: {
        available_products: state => {
            return state.availableProducts || [];
        },
    },
    actions: {
        async getDashboardPlaylistItems({ dispatch }) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/configuration/dashboard_playlist_items/`,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async getActiveProducts({ dispatch }) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/configuration/products/active/`,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return [];
            }
        },
        async getOnboardingProducts({ dispatch }) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/configuration/products/onboarding/`,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async getSolarProjects({ dispatch }) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/configuration/solar/projects/`,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async getSolarProject({ dispatch }, payload) {
            try {
                if (payload.slug !== undefined) {
                    const { data } = await axios.get(`${API_ROOT}/configuration/solar/projects/${payload.slug}/`);
                    return data;
                } else {
                    const { data } = await axios.get(`${API_ROOT}/configuration/solar/projects/legacy/${payload.id}/`);
                    return data;
                }
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
