import { addOrEditItem, deleteItem, getItems } from '@/store/api';


export default {
    namespaced: true,
    actions: {
        getMessages: getItems('messages/'),
        deleteMessage: deleteItem('messages/'),
        createMessage: addOrEditItem('messages/'),
        updateMessage: addOrEditItem('messages/'),  // Similar to line above, naming simplification

        getThreads: getItems('threads/'),
        deleteThread: deleteItem('threads/'),
        createThread: addOrEditItem('threads/'),
        updateThread: addOrEditItem('threads/'),  // Similar to line above, naming simplification
    },
};
