import debounce from 'lodash.debounce';
import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import { debug, handleWebpackError } from './store/constants';
import { trackEvent } from '@/mixpanel';
import { ANIMATION_LENGTHS } from '@/constants';

const DesignSystem = () => import('./pages/DesignSystem.vue').catch(handleWebpackError);

const AdvisorClientDetails = () => import('./pages/advisor/ClientDetails.vue').catch(handleWebpackError);
const AdvisorClientOnboardingDetails = () => import(
    './pages/advisor/ClientOnboardingDetails.vue').catch(handleWebpackError);
const AdvisorClientManagement = () => import('./pages/advisor/ClientManagement.vue').catch(handleWebpackError);
const AdvisorDashboard = () => import('./pages/advisor/Dashboard.vue').catch(handleWebpackError);
const AdvisorSelectUser = () => import('./pages/advisor/SelectUser.vue').catch(handleWebpackError);
const AdvisorTeamManagement = () => import('./pages/advisor/TeamManagement.vue').catch(handleWebpackError);

const Dashboard = () => import('./pages/Dashboard.vue').catch(handleWebpackError);
const Documents = () => import('./pages/Documents.vue').catch(handleWebpackError);
const GuidedPlanner = () => import('./pages/GuidedPlanner.vue').catch(handleWebpackError);
const GuidedPlannerFlow = () => import('./pages/GuidedPlannerFlow.vue').catch(handleWebpackError);
const Home = () => import('./pages/Home.vue').catch(handleWebpackError);

const OnboardingDetails = () => import('./pages/onboarding/OnboardingDetails.vue').catch(handleWebpackError);
const OnboardingForm = () => import('./pages/onboarding/OnboardingProfile.vue').catch(handleWebpackError);
const OnboardingInitiate = () => import('./pages/onboarding/OnboardingInitiate.vue').catch(handleWebpackError);
const OnboardingStrategy = () => import('./pages/onboarding/OnboardingStrategy.vue').catch(handleWebpackError);
const TrustAgreementReview = () => import('./pages/onboarding/TrustAgreementReview.vue').catch(handleWebpackError);
const TrustAgreementSign = () => import('./pages/onboarding/TrustAgreementSign.vue').catch(handleWebpackError);

const SolarActivities = () => import('./pages/SolarActivities.vue').catch(handleWebpackError);
const SolarProjects = () => import('./pages/SolarProjects.vue').catch(handleWebpackError);
const SolarProjectsSaved = () => import('./pages/SolarProjectsSaved.vue').catch(handleWebpackError);
const SolarProjectDetails = () => import('./pages/SolarProjectDetails.vue').catch(handleWebpackError);

const CalculatorClat = () => import('./pages/model/CalculatorClat.vue').catch(handleWebpackError);
const CalculatorCrut = () => import('./pages/model/CalculatorCrut.vue').catch(handleWebpackError);
const CalculatorCrutReport = () => import('./pages/model/CalculatorCrutReport.vue').catch(handleWebpackError);
const CalculatorGrat = () => import('./pages/model/CalculatorGrat.vue').catch(handleWebpackError);
const CalculatorIra = () => import('./pages/model/CalculatorIra.vue').catch(handleWebpackError);
const CalculatorOilGas = () => import('./pages/model/CalculatorOilGas.vue').catch(handleWebpackError);
const CalculatorPpli = () => import('./pages/model/CalculatorPpli.vue').catch(handleWebpackError);
const CalculatorQsbs = () => import('./pages/model/CalculatorQsbs.vue').catch(handleWebpackError);
const CalculatorSolar = () => import('./pages/model/CalculatorSolar.vue').catch(handleWebpackError);
const CalculatorComparisonEstate = () => import(
    './pages/model/CalculatorComparisonEstate.vue').catch(handleWebpackError);
const CalculatorComparisonCapitalGains = () => import(
    './pages/model/CalculatorComparisonCapitalGains.vue').catch(handleWebpackError);
const CalculatorComparisonOrdinaryIncome = () => import(
    './pages/model/CalculatorComparisonOrdinaryIncome.vue').catch(handleWebpackError);

const Activate = () => import('./pages/Activate.vue').catch(handleWebpackError);
const ForgotPassword = () => import('./pages/ForgotPassword.vue').catch(handleWebpackError);
const Login = () => import('./pages/Login.vue').catch(handleWebpackError);
const Messages = () => import('./pages/Messages.vue').catch(handleWebpackError);
const PasswordReset = () => import('./pages/PasswordReset.vue').catch(handleWebpackError);
const Profile = () => import('./pages/Profile.vue').catch(handleWebpackError);
const Register = () => import('./pages/Register.vue').catch(handleWebpackError);
const OilGasQuestionnaire = () => import('./pages/OilGasQuestionnaire').catch(handleWebpackError);
const RiskAnalysis = () => import('./pages/RiskAnalysis').catch(handleWebpackError);
const Tasks = () => import('./pages/Tasks.vue').catch(handleWebpackError);

const PrivacyPolicy = () => import('./pages/PrivacyPolicy.vue').catch(handleWebpackError);
const TermsOfService = () => import('./pages/TermsOfService.vue').catch(handleWebpackError);

const AdminCreateDistributionReport = () => import
    ('./pages/admin/CreateDistributionReport.vue').catch(handleWebpackError);
const AdminUserDetails = () => import('./pages/admin/UserDetails.vue').catch(handleWebpackError);
const AdminOnboardingTrustAgreementEmail = () => import(
    './pages/admin/OnboardingTrustAgreementEmail.vue').catch(handleWebpackError);
const AdminPdfFiller = () => import('./pages/admin/PdfFiller.vue').catch(handleWebpackError);


Vue.use(Router);

const path = debug ? '/' : (process.env.BASE_URL || '/');


const router = new Router({
    mode: 'history',
    base: path,
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '*',
            name: 'catchall',
            component: Login,
        },
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/clear/',
            name: 'clear',
            beforeEnter() {
                store.dispatch('logout');
                window.localStorage.clear();
                window.location.href = '/';
            },
        },

        {
            path: '/activate/:uid/:token/',
            name: 'activate',
            component: Activate,
            meta: {
                title: 'Activate',
                metaTags: [
                    { name: 'referrer', content: 'no-referrer' },
                ],
            },
        },
        {
            path: '/login/',
            name: 'login',
            component: Login,
            meta: { title: 'Welcome to Valur' },  // TODO: Respect WLP name
        },
        {
            path: '/privacy-policy/',
            name: 'privacy-policy',
            component: PrivacyPolicy,
            meta: { title: 'Privacy Policy' },
        },
        {
            path: '/password-reset/:uid/:token/',
            name: 'password-reset',
            component: PasswordReset,
            meta: {
                title: 'Reset password',
                metaTags: [
                    { name: 'referrer', content: 'no-referrer' },
                ],
            },
        },
        {
            path: '/terms-of-service/',
            name: 'terms-of-service',
            component: TermsOfService,
            meta: { title: 'Terms of Service' },
        },



        {
            path: '/dashboard/',
            name: 'dashboard',
            component: Dashboard,
            meta: { title: 'Dashboard' },
        },

        {
            path: '/advisor-client-details/:id',
            name: 'advisor-client-details',
            component: AdvisorClientDetails,
            meta: { title: 'Advisor - Client details' },
        },
        {
            path: '/advisor-client-management/',
            name: 'advisor-client-management',
            component: AdvisorClientManagement,
            meta: { title: 'Advisor - Client management' },
        },
        {
            path: '/advisor-client-onboarding-details/:product_label/:client_id/:onboarding_id/',
            name: 'advisor-client-onboarding-details',
            component: AdvisorClientOnboardingDetails,
            meta: { title: 'Advisor - Client onboarding details' },
        },
        {
            path: '/advisor-dashboard/',
            name: 'advisor-dashboard',
            component: AdvisorDashboard,
            meta: { title: 'Advisor - Dashboard' },
        },
        {
            path: '/select-user/',
            name: 'select-user',
            component: AdvisorSelectUser,
            meta: { title: 'Advisor - Select user' },
        },
        {
            path: '/advisor-team-management/',
            name: 'advisor-team-management',
            component: AdvisorTeamManagement,
            meta: { title: 'Advisor - Team management' },
        },

        {
            path: '/admin/design-system/',
            name: 'design-system',
            component: DesignSystem,
            meta: { title: 'Design system' },
        },
        {
            path: '/documents/',
            name: 'documents',
            component: Documents,
            meta: { title: 'Documents' },
        },
        {
            path: '/forgot-password/',
            name: 'forgot-password',
            component: ForgotPassword,
            meta: { title: 'Forgot password' },
        },
        {
            path: '/guided-planner/',
            name: 'guided-planner',
            component: GuidedPlanner,
            meta: { title: 'Guided planner' },
        },
        {
            path: '/guided-planner-flow/',
            name: 'guided-planner-flow',
            component: GuidedPlannerFlow,
            meta: { title: 'Guided planner flow' },
        },
        {
            path: '/messages/',
            name: 'messages',
            component: Messages,
            meta: { title: 'Messages' },
        },
        {
            path: '/profile/',
            name: 'profile',
            component: Profile,
            meta: { title: 'Profile' },
        },
        {
            path: '/redirect/',
            name: 'redirect',
            beforeEnter(to) {
                if (to.query.url) {
                    trackEvent('Redirect', { url: to.query.url });
                    window.location.href = to.query.url;
                }
                else {
                    router.push({ name: 'home' });
                }
            },
        },
        {
            path: '/register/',
            name: 'register',
            component: Register,
            meta: { title: 'Register' },
        },
        {
            path: '/register/:uid?/:token?',
            name: 'register-advisor-link',
            component: Register,
            meta: { title: 'Register' },
        },
        {
            path: '/tasks/',
            name: 'tasks',
            component: Tasks,
            meta: { title: 'Tasks' },
        },

        // Trust
        {
            path: '/trust-agreement-sign/',
            name: 'trust-agreement-sign',
            component: TrustAgreementSign,
            meta: { title: 'Trust agreement sign' },
        },
        {
            path: '/trust-agreement-review/',
            name: 'trust-agreement-review',
            component: TrustAgreementReview,
            meta: { title: 'Trust agreement review' },
        },

        // Model
        {
            path: '/charitable-remainder-trust-calculator/',
            name: 'charitable-remainder-trust-calculator',
            component: CalculatorCrut,
            meta: {
                title: 'Charitable Remainder Trust calculator',
                metaTags: [
                    {
                        name: 'description',
                        content: 'An easy-to-use Charitable Remainder Trust calculator, or CRT calculator, to help ' +
                            'calculate potential tax benefits, charitable tax deductions and returns.',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/charitable-remainder-trust-calculator',
                    },
                ],
            },
        },
        {
            path: '/charitable-remainder-trust-report/',
            name: 'charitable-remainder-trust-report',
            component: CalculatorCrutReport,
            meta: {
                title: 'Charitable remainder trust report',
            },
        },
        {
            path: '/compare-capital-gains-tax-savings/',
            name: 'compare-capital-gains-tax-savings',
            component: CalculatorComparisonCapitalGains,
            meta: {
                title: 'Compare capital gains tax savings',
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/compare-capital-gains-tax-savings',
                    },
                ],
            },
        },
        {
            path: '/compare-ordinary-income-tax-savings/',
            name: 'compare-ordinary-income-tax-savings',
            component: CalculatorComparisonOrdinaryIncome,
            meta: {
                title: 'Compare ordinary income tax savings',
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/compare-ordinary-income-tax-savings',
                    },
                ],
            },
        },
        {
            path: '/compare-estate-tax-savings/',
            name: 'compare-estate-tax-savings',
            component: CalculatorComparisonEstate,
            meta: {
                title: 'Compare estate tax savings',
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/compare-estate-tax-savings',
                    },
                ],
            },
        },
        {
            path: '/clat-calculator/',
            name: 'clat-calculator',
            component: CalculatorClat,
            meta: {
                title: 'Charitable Lead Annuity Trust Returns Calculator',
                metaTags: [
                    {
                        name: 'description',
                        content: 'An easy-to-use customizable calculator to get a glimpse of the potential ' +
                            'financial gains from tax planning with a Charitable Lead Trust.',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/clat-calculator',
                    },
                ],
            },
        },
        {
            path: '/grat-calculator/',
            name: 'grat-calculator',
            component: CalculatorGrat,
            meta: {
                title: 'GRAT Calculator',
                metaTags: [
                    {
                        name: 'keywords',
                        content: 'grat calculator, grantor retained annuity trust calculator, how to calculate ' +
                            'grat annuity payment, annuity calculator excel, zeroed out grat calculator',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/grat-calculator',
                    },
                ],
            },
        },
        {
            path: '/ira-calculator/',
            name: 'ira-calculator',
            component: CalculatorIra,
            meta: {
                title: 'IRA Calculator',
                metaTags: [
                    {
                        name: 'description',
                        content: 'IRA-to-Trust Rollover Returns Calculator.',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/ira-calculator',
                    },
                ],
            },
        },
        {
            path: '/model-calculator/',
            redirect: (to) => {
                trackEvent('Deprecated redirect', { path: to.path });
                return 'charitable-remainder-trust-calculator';
            },
        },
        {
            path: '/model-clat-calculator/',
            redirect: (to) => {
                trackEvent('Deprecated redirect', { path: to.path });
                return 'clat-calculator';
            },
        },
        {
            path: '/model-crut-calculator/',
            redirect: (to) => {
                trackEvent('Deprecated redirect', { path: to.path });
                return 'charitable-remainder-trust-calculator';
            },
        },
        {
            path: '/oil-gas-calculator/',
            name: 'oil-gas-calculator',
            component: CalculatorOilGas,
            meta: {
                title: 'Oil & Gas Calculator',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Evaluate the tax benefits of Oil & Gas projects.',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/oli-gas-calculator',
                    },
                ],
            },
        },
        {
            path: '/ppli-calculator/',
            name: 'ppli-calculator',
            component: CalculatorPpli,
            meta: {
                title: 'PPLI Calculator',
                metaTags: [
                    {
                        name: 'description',
                        content: 'The PPLI calculator provides the ability to calculate the premium and potential ' +
                            'benefits of a Private Placement Life Insurance policy based on your specific ' +
                            'financial situation.',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/ppli-calculator',
                    },
                ],
            },
        },
        {
            path: '/qsbs-calculator/',
            name: 'qsbs-calculator',
            component: CalculatorQsbs,
            meta: {
                title: 'QSBS Calculator for Stacking',
                metaTags: [
                    {
                        name: 'description',
                        content: 'QSBS Calculator to evaluate the total payouts and compare the potential tax ' +
                            'benefits of QSBS stacking to not stacking.',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/qsbs-calculator',
                    },
                ],
            },
        },
        {
            path: '/solar-calculator/',
            name: 'solar-calculator',
            component: CalculatorSolar,
            meta: {
                title: 'Solar Calculator',
                metaTags: [
                    {
                        name: 'description',
                        content: 'Evaluate the tax benefits of Solar projects.',
                    },
                    {
                        name: 'robots',
                        content: 'all',
                    },
                ],
                links: [
                    {
                        rel: 'canonical',
                        href: 'https://app.valur.com/solar-calculator',
                    },
                ],
            },
        },

        // Onboarding
        {
            path: '/onboarding-details/:onboarding_id/',
            name: 'onboarding-details',
            component: OnboardingDetails,
            meta: { title: 'Onboarding details' },
        },
        {
            path: '/onboarding-form/:onboarding_id/',
            name: 'onboarding-form',
            component: OnboardingForm,
            meta: { title: 'Onboarding profile' },
        },
        {
            path: '/onboarding-initiate/:product_label/',
            name: 'onboarding-initiate',
            component: OnboardingInitiate,
            meta: { title: 'Onboarding initiation' },
        },
        {
            path: '/onboarding-strategy/:user_id?',
            name: 'onboarding-strategy',
            component: OnboardingStrategy,
            meta: { title: 'Onboarding strategy' },
            beforeEnter(to, from, next) {
                const userId = to?.params?.user_id;
                if (!userId && store.getters.isAdvisor) {
                    router.push({
                        name: 'select-user',
                        params: { target_name: 'onboarding-strategy' },
                    });
                } else {
                    next();
                }
            },
        },
        {
            path: '/oil-gas-questionnaire/',
            name: 'oil-gas-questionnaire',
            component: OilGasQuestionnaire,
            meta: { title: 'Investment Questionnaire' },
        },
        {
            path: '/risk-analysis/',
            name: 'risk-analysis',
            component: RiskAnalysis,
            meta: { title: 'Risk analysis' },
        },
        {
            path: '/solar-activities/',
            name: 'solar-activities',
            component: SolarActivities,
            meta: { title: 'Solar activity log' },
        },
        {
            path: '/solar-projects/:onboarding_id?/',
            name: 'solar-projects',
            component: SolarProjects,
            meta: { title: 'Solar projects' },
        },
        {
            path: '/solar-projects-saved/',
            name: 'solar-projects-saved',
            component: SolarProjectsSaved,
            meta: { title: 'Saved solar projects' },
        },
        {
            path: '/solar-project-details/:slug/:onboarding_id?/',
            name: 'solar-project-details',
            component: SolarProjectDetails,
            props: true,
            meta: { title: 'Solar project details' },
        },

        {
            path: '/admin/create-distribution-report/:user_id/:onboarding_id',
            name: 'admin-create-distribution-report',
            component: AdminCreateDistributionReport,
            meta: { title: 'Create Distribution Report' },
        },
        {
            path: '/admin/pdf-filler/:form_id?/:user_id?/:onboarding_id?',
            name: 'admin-pdf-filler',
            component: AdminPdfFiller,
            meta: { title: 'PDF Filler' },
        },
        {
            path: '/admin/user-details/:user_id',
            name: 'admin-user-details',
            component: AdminUserDetails,
            meta: { title: 'User Details' },
        },
        {
            path: '/admin/user-details/:user_id/:onboarding_id/send-trust-agreement-email/',
            name: 'admin-onboarding-trust-agreement-email',
            component: AdminOnboardingTrustAgreementEmail,
            meta: { title: 'Compose Trust Agreement Email' },
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        try {
            document.getElementById('top-nav').scrollIntoView();
        } catch (e) {
            // do nothing
        }

        if (from.meta?.transitionAnimation) {
            return new Promise(
                (resolve) => setTimeout(
                    () => resolve({ x: 0, y: 0 }),
                    ANIMATION_LENGTHS[from.meta?.transitionAnimation.animationDuration],
                ),
            );
        }

        return { x: 0, y: 0 };
    },
});

export const needsAdvisor = (to) => {
    const isAdvisor = store.getters.isAdvisor;
    return !isAdvisor && to.name.indexOf('advisor-') === 0;
};

export const needsLogin = (to) => {
    const loggedIn = store.getters.isLoggedIn;
    return (
        to !== '/'
        && to !== '/forgot-password'
        && to.name !== 'activate'
        && to.name !== 'calculators'
        && to.name !== 'charitable-remainder-trust-calculator'
        && to.name !== 'charitable-remainder-trust-report'
        && to.name !== 'clat-calculator'
        && to.name !== 'clear'
        && to.name !== 'compare-estate-tax-savings'
        && to.name !== 'compare-capital-gains-tax-savings'
        && to.name !== 'compare-ordinary-income-tax-savings'
        && to.name !== 'dashboard'
        && to.name !== 'dashboard-v2'
        && to.name !== 'forgot-password'
        && to.name !== 'grat-calculator'
        && to.name !== 'guided-planner'
        && to.name !== 'guided-planner-flow'
        && to.name !== 'home'
        && to.name !== 'ira-calculator'
        && to.name !== 'login'
        && to.name !== 'model-calculator'
        && to.name !== 'model-clat-calculator'
        && to.name !== 'model-crut-calculator'
        && to.name !== 'model-ira-calculator'
        && to.name !== 'password-reset'
        && to.name !== 'privacy-policy'
        && to.name !== 'profile'
        && to.name !== 'oil-gas-calculator'
        && to.name !== 'ppli-calculator'
        && to.name !== 'qsbs-calculator'
        && to.name !== 'redirect'
        && to.name !== 'solar-activities'
        && to.name !== 'solar-calculator'
        && to.name !== 'solar-projects'
        && to.name !== 'solar-projects-saved'
        && to.name !== 'solar-project-details'
        && to.name !== 'terms-of-service'
        && (to.name || '').indexOf('register') !== 0
        && !loggedIn
    );
};

router.beforeEach((to, from, next) => {
    store.dispatch(
        'transition_animation/updateTransitionAnimation',
        {
            from: from.meta?.transitionAnimation,
            to: to.meta?.transitionAnimation,
        });
    next();
});

router.beforeEach(debounce(async (to, from, next) => {
    if (to.name === 'clear') {
        next();
    }

    const needsToBeAdvisor = needsAdvisor(to);
    const needsToLogin = needsLogin(to);

    let advisory_partner_profile = await store.dispatch('marketing/getAdvisoryPartnerProfile');
    const advisory_partner_name = advisory_partner_profile.name;
    if (advisory_partner_name) {
        if (advisory_partner_name.toLowerCase().indexOf('valur') === -1) {
            trackEvent('Advisory Partner hit', { name: advisory_partner_name });
        }
        store.commit('marketing/updateAdvisoryPartnerProfile', advisory_partner_profile);
    }

    const loggedIn = store.getters.isLoggedIn;
    if (loggedIn) {
        const isAdvisor = store.getters.isAdvisor;
        const isImpersonating = store.getters.isImpersonating;
        if (isAdvisor) {
            if (isImpersonating) {
                // Since we're getting Advisory Partner Profile based on URL, it's
                // necessary to replace it manually when we're impersonating.
                // This data is being populated using UserImpersonationSerializer
                advisory_partner_profile = store.getters.user.advisory_partner;
                if (advisory_partner_profile) {
                    store.commit('marketing/updateAdvisoryPartnerProfile', advisory_partner_profile);
                }
            }
            const advisor_profile = await store.dispatch(
                'marketing/getAdvisorProfile', advisory_partner_profile.id);
            store.commit('marketing/updateAdvisorProfile', advisor_profile);
        }
    }
    if (needsToLogin) {
        await router.push({ name: 'login', query: { next: to.path } });
    } else if (needsToBeAdvisor) {
        await router.push({ name: 'dashboard' });
    } else {
        try {
            next();
        } catch (e) {
            handleWebpackError();
        }
        const tags = new Set();
        const links = new Set();
        if (to.meta) {
            if (to.meta.title) {
                document.title = to.meta.title;
            }

            let appendDefaultDescription = false;
            if ((to.meta.metaTags || []).length === 0) {
                appendDefaultDescription = true;
            } else {
                if (to.meta.metaTags.filter(e => e.name === 'description').length === 0) {
                    appendDefaultDescription = true;
                }
            }
            if (appendDefaultDescription) {
                to.meta.metaTags = [
                    {
                        name: 'description',
                        content: 'Valur is the simplest way to identify, set up, and take care of optimized tax ' +
                            'and estate-planning structures typically used by the ultrawealthy.',
                    },
                ];
            }

            (to.meta.metaTags || []).forEach(tag => {
                tags.add(tag.name);
                let append = false;
                let node = document.querySelector(`meta[name=${tag.name}]`);
                if (!node) {
                    node = document.createElement('meta');
                    node.setAttribute('data-valur', true);
                    append = true;
                }
                node.name = tag.name;
                node.content = tag.content;
                if (append) {
                    document.querySelector('head').appendChild(node);
                }
            });
            (to.meta.links || []).forEach(link => {
                links.add(link.rel);
                let append = false;
                let node = document.querySelector(`link[rel=${link.rel}]`);
                if (!node) {
                    node = document.createElement('link');
                    node.setAttribute('data-valur', true);
                    append = true;
                }
                node.rel = link.rel;
                node.href = link.href;
                if (append) {
                    document.querySelector('head').appendChild(node);
                }
            });
        } else {
            document.title = 'Valur';
        }
        document.querySelectorAll('meta[data-valur=true]').forEach(node => {
            if (!tags.has(node.name)) {
                document.querySelector('head').removeChild(node);
            }
        });
        document.querySelectorAll('link[data-valur=true]').forEach(node => {
            if (!links.has(node.rel)) {
                document.querySelector('head').removeChild(node);
            }
        });
    }
}, 10));

export default router;
