import axios from "axios";
import { ASGARD_API_ROOT, logApiError } from "./api";

export default {
  namespaced: true,
  actions: {
    async listForms({ dispatch }) {
      try {
        const response = await axios.get(`${ASGARD_API_ROOT}/forms`);
        return response["data"];
        
      } catch (e) {
        logApiError(e, dispatch);
        return [];
      }
    },
    async getDependencyExtractionOptions({ dispatch }, dependencyLabel) {
      try {
        const response = await axios.get(
          `${ASGARD_API_ROOT}/forms/${dependencyLabel}/extraction-methods`,
        );
        return response["data"];
      } catch (e) {
        logApiError(e, dispatch);
        return [];
      }
    },
    async calculateForm({ dispatch }, { formLabel, formFields }) {
      try {
        const response = await axios.post(
          `${ASGARD_API_ROOT}/forms/${formLabel}/calculate`,
          formFields,
        );
        return response["data"];
      } catch (e) {
        logApiError(e, dispatch);
        throw e;
      }
    },
    async extractFile({ dispatch }, { formLabel, extractionMethod, file }) {
      try {
        const response = await axios.post(
          `${ASGARD_API_ROOT}/forms/${formLabel}/extract`,
          (() => {
            const formData = new FormData();
            formData.append("file", file);
            return formData;
          })(),
          {
            params: {
              method: extractionMethod,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        return response["data"];
      } catch (e) {
        logApiError(e, dispatch);
        throw e;
      }
    },
    async getFormFields({ dispatch }, formLabel) {
      try {
        const response = await axios.get(
          `${ASGARD_API_ROOT}/forms/${formLabel}/fields`,
        );
        return response["data"];
      } catch (e) {
        logApiError(e, dispatch);
        return [];
      }
    },
    async getDependencyFormFields(
      { dispatch },
      { formLabel, dependencyFormLabel },
    ) {

      try {
        const response = await axios.get(
          `${ASGARD_API_ROOT}/forms/${formLabel}/dependencies/${dependencyFormLabel}/fields`,
        );
        return response["data"];
      } catch (e) {
        logApiError(e, dispatch);
        return [];
      }
    },
    async fillForm({ dispatch }, { formLabel, formFields }) {
      try {
        const response = await axios.post(
          `${ASGARD_API_ROOT}/forms/${formLabel}/fill`,
          formFields,
        );
        return response["data"];
      } catch (e) {
        logApiError(e, dispatch);
        return [];
      }
    },
  },
};
