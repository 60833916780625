import { getYearNumber } from './date_utils.js';

export const logError = (error) => {
    console.log('Internal Error', error.message);
};

export const STATES = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const STATES_ABBR = {
    'Arizona': 'AZ',
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'District of Columbia': 'DC',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY',
};

export const STATE_HIGH_TAX = {
    'Alabama': false,
    'Alaska': false,
    'Arizona': false,
    'Arkansas': false,
    'California': true,
    'Colorado': false,
    'Connecticut': true,
    'Delaware': false,
    'District of Columbia': true,
    'Florida': false,
    'Georgia': false,
    'Hawaii': true,
    'Idaho': false,
    'Illinois': true,
    'Indiana': false,
    'Iowa': false,
    'Kansas': false,
    'Kentucky': false,
    'Louisiana': false,
    'Maine': true,
    'Maryland': true,
    'Massachusetts': true,
    'Michigan': false,
    'Minnesota': true,
    'Mississippi': false,
    'Missouri': false,
    'Montana': false,
    'Nebraska': false,
    'Nevada': false,
    'New Hampshire': false,
    'New Jersey': true,
    'New Mexico': false,
    'New York': true,
    'North Carolina': false,
    'North Dakota': false,
    'Ohio': false,
    'Oklahoma': false,
    'Oregon': true,
    'Pennsylvania': false,
    'Rhode Island': true,
    'South Carolina': false,
    'South Dakota': false,
    'Tennessee': false,
    'Texas': false,
    'Utah': false,
    'Vermont': true,
    'Virginia': false,
    'Washington': false,
    'West Virginia': false,
    'Wisconsin': false,
    'Wyoming': false,
};

export const STATE_TAXES = {
    'Arizona': 8,
    'Alabama': 5,
    'Alaska': 0,
    'Arkansas': 5.9,
    'California': 13.3,
    'Colorado': 4.55,
    'Connecticut': 6.99,
    'Delaware': 6.6,
    'District of Columbia': 8.95,
    'Florida': 0,
    'Georgia': 5.75,
    'Hawaii': 11,
    'Idaho': 6.925,
    'Illinois': 4.95,
    'Indiana': 3.23,
    'Iowa': 8.53,
    'Kansas': 5.7,
    'Kentucky': 5,
    'Louisiana': 6,
    'Maine': 7.15,
    'Maryland': 5.75,
    'Massachusetts': 5,
    'Michigan': 4.25,
    'Minnesota': 9.85,
    'Mississippi': 5,
    'Missouri': 5.4,
    'Montana': 6.9,
    'Nebraska': 6.84,
    'Nevada': 0,
    'New Hampshire': 5,
    'New Jersey': 10.75,
    'New Mexico': 5.9,
    'New York': 12.71,
    'North Carolina': 5.25,
    'North Dakota': 2.9,
    'Ohio': 4.797,
    'Oklahoma': 5,
    'Oregon': 9.9,
    'Pennsylvania': 3.07,
    'Rhode Island': 5.99,
    'South Carolina': 7,
    'South Dakota': 0,
    'Tennessee': 0,
    'Texas': 0,
    'Utah': 4.95,
    'Vermont': 8.75,
    'Virginia': 5.75,
    'Washington': 6,
    'West Virginia': 6.5,
    'Wisconsin': 7.65,
    'Wyoming': 0,
};

export const FILING_STATUS_SINGLE = 'single';
export const FILING_STATUS_MARRIED_FILING_JOINTLY = 'married_filing_jointly';
export const FILING_STATUSES = {
    [FILING_STATUS_SINGLE]: 'Single',
    [FILING_STATUS_MARRIED_FILING_JOINTLY]: 'Married, filing jointly',
    // 'married_filing_separately': 'Married filing separately',
    // 'head_of_household': 'Head of household',
};

export const DEPRECIATION_CAP = {
    [FILING_STATUS_SINGLE]: 313000,
    [FILING_STATUS_MARRIED_FILING_JOINTLY]: 626000,
};

export const MENU_ADMIN_ITEMS = {
    PdfFiller: Symbol('pdf-filler'),
};

export const MENU_ADVISOR_ITEMS = {
    AdvisorDashboard: Symbol('advisor-dashboard'),
    ClientManagement: Symbol('client-management'),
    TeamManagement: Symbol('team-management'),
};

export const MENU_USER_ITEMS = {
    Calculators: Symbol('calculators'),
    Dashboard: Symbol('dashboard'),
    Documents: Symbol('documents'),
    GuidedPlanner: Symbol('guided-planner'),
    KnowledgeLibrary: Symbol('knowledge-library'),
    Messages: Symbol('messages'),
    Notifications: Symbol('notifications'),
    Onboarding: Symbol('onboarding'),
    OilGasQuestionnaire: Symbol('oil-gas-questionnaire'),
    RiskAnalysis: Symbol('risk-analysis'),
    SavedProjects: Symbol('saved-projects'),
    SolarActivities: Symbol('solar-activities'),
    SolarProjects: Symbol('solar-projects'),
    Tasks: Symbol('tasks'),
};

export const CRUT_TRUST_LENGTHS = {
    'lifetime': 'Lifetime',
    'term': 'Term',
};
export const CRUT_TRUST_STRATEGIES = {
    'flip_crut': 'Flip CRUT',
    'nimcrut': 'NIMCRUT',
    'standard_crut': 'Standard CRUT',
};
export const CRUT_MAX_OPTIONS = {
    'max_length': 'Max length',
    'max_rate': 'Max rate',
};

export const CALENDLY_BOOKING_URL = 'https://valur.com/forms/schedule';
export const CALENDLY_GRAT_URL = 'https://calendly.com/d/29c-wzn-mvd/grat-trust';
export const CALENDLY_TRUST_SIGNING_URL = 'https://calendly.com/d/ch5-yhd-53h/valur-trust-signing';
export const CALENDLY_SOLAR_QUESTIONS_URL = 'https://calendly.com/d/2ft-hzb-hxq/solar-tax-credits';
export const LIFETIME_LOWER_BOUND = 27;
export const LIFETIME_UPPER_BOUND = 90;
export const LAST_YEAR = getYearNumber() - 1;

export const ADVISORY_PARTNER_MEMBER_ROLES = {
    admin: 'Admin',
    member: 'Member',
};

export const CONTINGENT_BENEFICIARY_CLOSEST_RELATIVES = 'closest_relatives';
export const CONTINGENT_BENEFICIARY_CHARITIES_BY_TRUSTEE = 'charities_by_trustee';
export const CONTINGENT_BENEFICIARY_SPECIFIC_CHARITY = 'specific_charity';
export const CONTINGENT_BENEFICIARY_SPECIFIC_PERSON = 'specific_person';
export const CONTINGENT_BENEFICIARIES = [
    { key: CONTINGENT_BENEFICIARY_CLOSEST_RELATIVES, title: 'Your closest-living relatives' },
    {
        key: CONTINGENT_BENEFICIARY_CHARITIES_BY_TRUSTEE,
        title: 'The charities that the Trustee selects taking into account the charities you support.',
    },
    { key: CONTINGENT_BENEFICIARY_SPECIFIC_CHARITY, title: 'A specific charity' },
    { key: CONTINGENT_BENEFICIARY_SPECIFIC_PERSON, title: 'A specific person' },
];

export const GRAT_EXISTING_REMAINDER_TRUST = 'existing_remainder_trust';
export const GRAT_INDIVIDUAL_BENEFICIARIES = 'individual_beneficiaries';
export const GRAT_REMAINDER_TRUST = 'remainder_trust';
export const GRAT_BENEFICIARY_TYPES = {
    [GRAT_EXISTING_REMAINDER_TRUST]: 'Existing remainder trust',
    [GRAT_INDIVIDUAL_BENEFICIARIES]: 'Individual beneficiaries',
    [GRAT_REMAINDER_TRUST]: 'Remainder trust',
};

export const DISTRIBUTION_SCHEDULE_MONTHLY = 'monthly';
export const DISTRIBUTION_SCHEDULE_QUARTERLY = 'quarterly';
export const DISTRIBUTION_SCHEDULE_ANNUALLY = 'annually';

export const DISTRIBUTION_SCHEDULES = [
    { key: DISTRIBUTION_SCHEDULE_MONTHLY, title: 'Monthly' },
    { key: DISTRIBUTION_SCHEDULE_QUARTERLY, title: 'Quarterly' },
    { key: DISTRIBUTION_SCHEDULE_ANNUALLY, title: 'Annually' },
];

export const SOLAR_ACTIVITY_CATEGORY_DUE_DILIGENCE = 'due_diligence';
export const SOLAR_ACTIVITY_CATEGORY_EDUCATION = 'education';
export const SOLAR_ACTIVITY_CATEGORY_IN_PERSON_MEETING = 'in_person_meeting';
export const SOLAR_ACTIVITY_CATEGORY_OTHER = 'other';
export const SOLAR_ACTIVITY_CATEGORY_PROJECT_PARTICIPATION = 'project_participation';
export const SOLAR_ACTIVITY_CATEGORY_RESEARCH = 'research';
export const SOLAR_ACTIVITY_CATEGORY_SITE_VISIT = 'site_visit';
export const SOLAR_ACTIVITY_CATEGORIES = {
    [SOLAR_ACTIVITY_CATEGORY_DUE_DILIGENCE]: 'Due Diligence',
    [SOLAR_ACTIVITY_CATEGORY_EDUCATION]: 'Education',
    [SOLAR_ACTIVITY_CATEGORY_IN_PERSON_MEETING]: 'In-Person Meeting',
    [SOLAR_ACTIVITY_CATEGORY_PROJECT_PARTICIPATION]: 'Project Participation',
    [SOLAR_ACTIVITY_CATEGORY_RESEARCH]: 'Research',
    [SOLAR_ACTIVITY_CATEGORY_SITE_VISIT]: 'Site Visit',
    [SOLAR_ACTIVITY_CATEGORY_OTHER]: 'Other',
};
export const SOLAR_BONUS_DEPRECIATION_SHARE_1Y = 0.6;
export const SOLAR_EDUCATION_PLAYLISTS = [
    {
        title: "Phase 1",
        items: [
            'https://www.youtube.com/watch?v=px239v5o6xU',
            'https://www.youtube.com/watch?v=tmeA6iPYmm0',
            'https://www.youtube.com/watch?v=ImICXjtI_QM',
            'https://www.youtube.com/watch?v=y783yhFN2bM',
            'https://www.youtube.com/watch?v=tJxHM71QKoo',
            'https://www.youtube.com/watch?v=LNfj_NHMtjo',
            'https://www.youtube.com/watch?v=m7ir1fK_FTo',
            'https://www.youtube.com/watch?v=vnjMQLhp6LE',
            'https://www.youtube.com/watch?v=38udfisNPyo',
            'https://www.youtube.com/watch?v=OpTOKKKGWV8',
            'https://www.youtube.com/watch?v=tBxGo0qveNs',
        ],
    },
    {
        title: "Phase 2",
        items: [
            'https://www.youtube.com/watch?v=mzAi1hMUaDw',
            'https://www.youtube.com/watch?v=EcSbtQcy3k8',
            'https://www.youtube.com/watch?v=4rWYFv03DlU',
            'https://www.youtube.com/watch?v=gUXDG7SzIl8',
            'https://www.youtube.com/watch?v=4rWYFv03DlU',
            'https://www.youtube.com/watch?v=K12l9J3pLIU',
            'https://www.youtube.com/watch?v=tmeA6iPYmm0',
            'https://www.youtube.com/watch?v=EcSbtQcy3k8',
            'https://www.youtube.com/watch?v=_CjKnA2HR64',
            'https://www.youtube.com/watch?v=FIqlkcxYbR0',
            'https://www.youtube.com/watch?v=Rw0qlS-y1PI',
            'https://www.youtube.com/watch?v=k-dtlmzaFQM',
            'https://www.youtube.com/watch?v=qzNkqkFOJmk',
            'https://www.youtube.com/watch?v=pQB0QcZZYFI',
            'https://www.youtube.com/watch?v=E4oLECPDJe8',
            'https://www.youtube.com/watch?v=8U2CK5CZMdk',
            'https://www.youtube.com/watch?v=E4oLECPDJe8',
            'https://www.youtube.com/watch?v=G-3fUOGWftY',
            'https://www.youtube.com/watch?v=yoF7Srseeco',
            'https://www.youtube.com/watch?v=7qrWCvzOTaM',
        ],
    },
    {
        title: "Phase 3",
        items: [
            'https://www.youtube.com/watch?v=K12l9J3pLIU',
            'https://www.youtube.com/watch?v=SpcRjDulD7g',
            'https://www.youtube.com/watch?v=wgLLPjLZz9o',
            'https://www.youtube.com/watch?v=SpcRjDulD7g',
            'https://www.youtube.com/watch?v=l6T9xIeZTds',
            'https://www.youtube.com/watch?v=a5ENMhjcoxQ',
            'https://www.youtube.com/watch?v=RqdE53JhJ4E',
            'https://www.youtube.com/watch?v=8NXLKRW1IEU',
            'https://www.youtube.com/watch?v=Zy9u5_AcM0w',
            'https://www.youtube.com/watch?v=ILwnyUilh6M',
            'https://www.youtube.com/watch?v=gkwAwuWe9pA',
            'https://www.youtube.com/watch?v=CWhJ4QIJlfo',
            'https://www.youtube.com/watch?v=yAgYe7W-MUE',
            'https://www.youtube.com/watch?v=aGpP8x9FEqg',
            'https://www.youtube.com/watch?v=iI_Zzpp4V5c',
        ],
    },
];

export const SOLAR_PAYMENT_STRUCTURE_DISPLAY = {
    '100_upfront': '100% upfront payment',
    '50_50': '50% down, 50% upon completion',
    '25_75': '25% down, 75% upon completion',
};

export const SOLAR_PROJECT_AVAILABILITY_AVAILABLE = 'available';
export const SOLAR_PROJECT_AVAILABILITY_NOT_AVAILABLE = 'not_available';
export const SOLAR_PROJECT_AVAILABILITY_RESERVED = 'reserved';
export const SOLAR_PROJECT_AVAILABILITY_OPTIONS = {
    [SOLAR_PROJECT_AVAILABILITY_AVAILABLE]: 'Available',
    [SOLAR_PROJECT_AVAILABILITY_NOT_AVAILABLE]: 'Not available',
    [SOLAR_PROJECT_AVAILABILITY_RESERVED]: 'Reserved',
};

export const SOLAR_PROJECT_PURCHASE_MINIMUM = 150000;
export const SOLAR_PROJECT_TYPE_FLIP_PARTNERSHIP = 'flip_partnership';
export const SOLAR_PROJECT_TYPE_PPA = 'ppa';
export const SOLAR_PROJECT_TYPE_SALE_LEASEBACK = 'sale_leaseback';
export const SOLAR_PROJECT_TYPES = {
    [SOLAR_PROJECT_TYPE_SALE_LEASEBACK]: 'Sale-leaseback',
    [SOLAR_PROJECT_TYPE_FLIP_PARTNERSHIP]: 'Flip partnership',
};
export const ENERGY_PROJECT_TYPE_SOLAR = 'solar';
export const ENERGY_PROJECT_TYPE_OIL_AND_GAS = 'oil_and_gas';
export const ENERGY_PROJECT_TYPES = {
    [ENERGY_PROJECT_TYPE_SOLAR]: 'Solar',
    [ENERGY_PROJECT_TYPE_OIL_AND_GAS]: 'Oil & Gas',
};


export const SOLAR_PROJECT_OWNERSHIP_STRUCTURES = {
    [SOLAR_PROJECT_TYPE_FLIP_PARTNERSHIP]: 'Flip partnership',
    [SOLAR_PROJECT_TYPE_SALE_LEASEBACK]: 'Sale leaseback',
};

export const DOCUMENT_TYPE_ADDITIONAL_DOCUMENTS = 'additional_documents';
export const DOCUMENT_TYPE_DRIVERS_LICENSE = 'drivers_license';
export const DOCUMENTS_TRUST_AGREEMENT = 'trust_agreement';

export const GUIDED_PLANNER_VISIBILITY_THRESHOLD = 'md';

// Offset multiplier for chart lines to prevent overlapping tooltips
export const CHART_JITTER_OFFSET = 0.02;

// CRUT Calculator constants
export const CRUT_MIN_FLIP_YEAR = 5;
export const CRUT_MAX_FLIP_YEAR_OPTIONS = 3;
export const CRUT_FLIP_YEAR_INCREMENT = 5;

export const AVAILABLE_ANIMATIONS = {
    SLIDE_RIGHT: 'slideRight',
    SLIDE_LEFT: 'slideLeft',
    SLIDE_UP: 'slideUp',
    SLIDE_DOWN: 'slideDown',
    EXPAND_OUT: 'expandOut',
    SHRINK_DOWN: 'shrinkDown',
    FADE_IN: 'fadeIn',
    EMPTY: '',
};
export const ANIMATION_LENGTHS = {
    short: 200,
    medium: 450,
    long: 800,
};

export const ARTICLE_EXCERPT_MAX_LENGTH = 200;
export const TAILWIND_COLORS_DEV = ['gray', 'indigo', 'green', 'red'];
export const TAILWIND_COLORS_PROD = [
    'zinc', 'neutral', 'stone', 'orange', 'amber', 'yellow', 'lime', 'emerald',
    'teal', 'cyan', 'sky', 'blue', 'violet', 'purple', 'fuchsia', 'pink', 'rose',
];

export const NOTIFICATION_VISIBILITY_DURATION_SHORT = 2000;
export const NOTIFICATION_VISIBILITY_DURATION_NORMAL = 3000;
export const NOTIFICATION_VISIBILITY_DURATION_LONG = 5000;

export const HUBSPOT_DEAL_ONBOARDING_STAGE = 'Onboarding';
export const HUBSPOT_DEAL_QUALIFYING_STAGE = 'Qualifying';

export const MESSAGE_CENTER_MESSAGE_TYPE_USER = 'user';
export const MESSAGE_CENTER_MESSAGE_TYPE_SUPPORT = 'support';
export const MESSAGE_CENTER_MESSAGE_TYPE_SYSTEM = 'system';
export const MESSAGE_CENTER_MESSAGE_TYPES = {
    [MESSAGE_CENTER_MESSAGE_TYPE_USER]: 'User Message',
    [MESSAGE_CENTER_MESSAGE_TYPE_SUPPORT]: 'Support Message',
    [MESSAGE_CENTER_MESSAGE_TYPE_SYSTEM]: 'System Message',
};

export const SLACK_DEV_TEST_CHANNEL = 'dev-test';
export const SLACK_OPS_CUSTOMER_ACTIVITIES_CHANNEL = 'ops_customer_activities';
export const SLACK_OPS_CUSTOMER_MESSAGES_CHANNEL = 'ops_customer_messages';

export const DON_NOT_EXCLUDE_SUFFIX = '__do_not_exclude';

export const NUMBER_FORMAT_MILLION = 1000000;
export const NUMBER_FORMAT_BILLION = 1000000000;
export const NUMBER_FORMAT_SUFFIX_MILLION = 'M';
export const NUMBER_FORMAT_SUFFIX_BILLION = 'B';

export const LIFE_EXPECTANCY = {
    26: 59,
    27: 58,
    28: 57,
    29: 56,
    30: 55,
    31: 54,
    32: 53,
    33: 53,
    34: 52,
    35: 51,
    36: 50,
    37: 49,
    38: 48,
    39: 47,
    40: 46,
    41: 45,
    42: 44,
    43: 43,
    44: 42,
    45: 41,
    46: 40,
    47: 39,
    48: 38,
    49: 37,
    50: 36,
    51: 35,
    52: 34,
    53: 33,
    54: 33,
    55: 32,
    56: 31,
    57: 30,
    58: 29,
    59: 28,
    60: 27,
    61: 26,
    62: 25,
    63: 25,
    64: 24,
    65: 23,
    66: 22,
    67: 21,
    68: 20,
    69: 20,
    70: 19,
    71: 18,
    72: 17,
    73: 16,
    74: 16,
    75: 15,
    76: 14,
    77: 13,
    78: 13,
    79: 12,
    80: 11,
    81: 11,
    82: 10,
    83: 9,
    84: 9,
    85: 8,
    86: 8,
    87: 6,
    88: 7,
    89: 7,
    90: 6,
};
